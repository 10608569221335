<template>
  <div class="m">
    <div class="m-title">
      <div class="m-title-right">
        <!--        <searchGoodsInput @search="searchGoods"></searchGoodsInput>-->
        <div>
          <div class="mt-4">
            <el-input
              v-model="searchInput"
              placeholder="输厂家、系列、型号、订货号.....查价格"
              class="input-with-select"
              style="width: 500px; margin-right: 10px"
              @input="searchGoodsList"
              @focus="this.showSearchList = true"
            >
            </el-input>
            <el-button type="primary" size="mini" @click="searchGoods(this.searchInput)"
              >查找</el-button
            >
          </div>
          <div
            v-if="this.showSearchList && this.searchList && this.searchList.length"
            style="
              padding: 5px;
              width: 625px;
              overflow-y: auto;
              border: 1px solid red;
              cursor: pointer;
              max-height: 500px;
              position: absolute;
              top: 50px;
              z-index: 999;
              background: white;
            "
          >
            <el-table
              :data="searchList"
              style="width: 100%"
              :show-header="false"
              @cell-click="clickSearchItem"
            >
              <el-table-column prop="content">
                <template #default="scope">
                  <lable :innerHTML="scope.row.content"></lable>
                </template>
              </el-table-column>
            </el-table>
          </div>
        </div>
        <div class="flex">
          <ToAddInquiryPage></ToAddInquiryPage>
          <ContactWechat></ContactWechat>
        </div>
      </div>
      <div class="m-title-left">
        <tree-select
          style="width: 320px; margin-right: 10px"
          placeholder="请选择品牌"
          v-model="ruleForm.brandId"
          :clearable="false"
          :options="brandList"
          :disable-branch-nodes="true"
          :searchable="true"
          :defaultExpandLevel="10"
          :key="ruleForm.brandId"
          @select="(node) => treeChange(node, 'brandId')"
          :flatten-search-results="true"
        />
        <!-- <tree-select
                  style="width: 320px;margin-left: 20px;"
                  placeholder="请选择系列"
                  :clearable="false"
                  :options="seriesList2"
                  v-model="ruleForm.seriesId"
                  :key="ruleForm.seriesId"
                  :disable-branch-nodes="true"
                  :searchable="true"
                  :defaultExpandLevel="10"
                  @select="(node) => treeChange(node, 'seriesId')"
                  :flatten-search-results="true"
                /> -->
        <el-cascader
          :options="seriesList2"
          placeholder="系列"
          :show-all-levels="false"
          v-model="ruleForm.seriesId"
          style="position: relative; top: -1px"
          ruleFormable
          clearable
          @change="seriesChange"
        />
      </div>
    </div>

    <div class="line"></div>
    <div>
      <el-carousel height="150px">
        <el-carousel-item v-for="item in bannerList" :key="item">
          <!-- {{ item.img }} -->
          <el-image :src="item.img" style="width: 100%; height: 150px"></el-image>
        </el-carousel-item>
      </el-carousel>
    </div>
    <div class="line"></div>
    <div class="m-content">
      <div class="m-left">
        <div class="flex">
          <el-tabs v-model="ruleForm.type">
            <el-tab-pane
              :label="item.title"
              :name="item.name"
              v-for="(item, index) in navs"
              :key="index"
            />
          </el-tabs>
          <el-button type="primary" size="mini" @click="openNewUrl">官网</el-button>
        </div>
        <div class="m-l-content">
          <specsNoumenonScreen
            :data="specListData"
            v-if="ruleForm.type === 'SUBJECT'"
            @change="specChange"
          />
          <specsEenclosureScreen
            :data="specListData"
            v-else-if="ruleForm.type === 'R_ACCESSORY'"
            @change-spec="accessorySpecChange"
            @change="accessorySpecValueChange"
          />
        </div>
      </div>
      <div class="m-right">
        <div class="m-r-title">{{ categoryNumber }}</div>
        <div class="m-info" v-if="productInfo.id">
          <div class="m-info-left">
            <el-image :src="productInfo.src" style="width: 190px; height: 190px">
            </el-image>
          </div>
          <div class="m-info-right">
            <div class="m-info-row">
              <span>生产厂家:</span>
              <div class="m-info-text">{{ productInfo.brandName }}</div>
            </div>
            <div class="m-info-row">
              <span>面价:</span>
              <div class="m-info-text">
                ￥{{ productInfo.guidePrice }}/{{ productInfo.unit }}
              </div>
            </div>
            <div class="m-info-row">
              <span>参数:</span>
              <div class="m-info-text">
                {{ productInfo.auxiliaryRemarksOne }}
              </div>
            </div>
            <div class="m-info-row">
              <span>订货号:</span>
              <div class="m-info-text">{{ productInfo.articleNo }}</div>
            </div>
            <div class="m-info-row">
              <span>型号:</span>
              <div class="m-info-text">{{ productInfo.barCode }}</div>
            </div>
            <div class="m-info-row">
              <span>系列:</span>
              <div class="m-info-text">{{ productInfo.seriesName }}</div>
            </div>
          </div>
        </div>
        <el-empty description="暂无数据" :image-size="50" v-else></el-empty>
        <div class="m-r-title">产品信息</div>
        <lida-table
          :pagination="false"
          :cutHeight="120"
          :data="goodsList"
          border
          show-summary
          :summary-method="getSummaries"
          ref="table"
          @selection-change="selectGoods"
        >
          <el-table-column type="selection" width="55" />
          <lida-table-column label="产品信息">
            <template #default="scope">
              <div class="g-info">
                <el-image :src="scope.row.src" style="width: 100px; height: 100px">
                </el-image>
                <div class="g-info-right">
                  <div class="g-info-row g-info-title">
                    {{ scope.row.productName }} | {{ scope.row.seriesName }}
                  </div>
                  <div class="g-info-row">
                    <span>型号:</span>
                    <p>{{ scope.row.barCode }}</p>
                  </div>
                  <div class="g-info-row">
                    <span>订货号:</span>
                    <p>{{ scope.row.articleNo }}</p>
                  </div>
                  <div class="g-info-row">
                    <span>品牌:</span>
                    <p>{{ scope.row.brandName }}</p>
                  </div>
                </div>
                <!-- 替换商品 弹窗 -->
                <el-popover
                  v-if="
                    scope.row.skuReplaceVOList && scope.row.skuReplaceVOList.length > 0
                  "
                  placement="top"
                  width="600"
                  trigger="click"
                  v-model="visible"
                >
                  <div class="product-list-box">
                    <p class="p-title">可替换商品</p>
                    <div
                      class="p-list"
                      v-for="(item, index) in scope.row.skuReplaceVOList"
                      :key="index + 'product'"
                    >
                      <p class="p-l-title">{{ item.productName }}</p>
                      <div class="image-box">
                        <img :src="item.filesUrl" alt="" />
                      </div>
                      <div class="info-list">
                        <div class="info-item">
                          <p class="i-label">生产厂家：</p>
                          <p class="i-value">{{ item.brandName }}</p>
                        </div>
                        <div class="info-item">
                          <p class="i-label">面价：</p>
                          <p class="i-value">{{ item.guidePrice }}</p>
                        </div>
                        <div class="info-item">
                          <p class="i-label">参数：</p>
                          <p class="i-value">{{ item.param }}</p>
                        </div>
                        <div class="info-item">
                          <p class="i-label">订货号：</p>
                          <p class="i-value">{{ item.articleNo }}</p>
                        </div>
                        <div class="info-item">
                          <p class="i-label">型号：</p>
                          <p class="i-value">{{ item.barCode }}</p>
                        </div>
                        <div class="info-item">
                          <p class="i-label">系列：</p>
                          <p class="i-value">{{ item.seriesName }}</p>
                        </div>
                      </div>
                      <div class="add-number">
                        <p>数量</p>
                        <el-input-number v-model="item.num" :min="0"></el-input-number>
                      </div>
                    </div>
                    <div class="btn-box">
                      <el-button size="small" type="primary" @click="clickAddCart(scope)"
                        >加入购物车
                      </el-button>
                      <el-button
                        size="small"
                        type="primary"
                        @click="clickAddQuoteList(scope)"
                        >加入报价单
                      </el-button>
                    </div>
                  </div>
                  <template #reference>
                    <el-button
                      v-if="
                        scope.row.skuReplaceVOList &&
                        scope.row.skuReplaceVOList.length > 0
                      "
                      size="small"
                      class="change-btn"
                      type="danger"
                      plain
                      @click="visible = !visible"
                      >替换商品
                    </el-button>
                  </template>
                </el-popover>
              </div>
            </template>
          </lida-table-column>
          <lida-table-column label="面价">
            <template #default="scope">
              <div>{{ scope.row.guidePrice }}</div>
            </template>
          </lida-table-column>
          <lida-table-column label="数量">
            <template #default="scope">
              <el-input-number v-model="scope.row.num" :min="0" @change="handleChange" />
            </template>
          </lida-table-column>
        </lida-table>
        <div class="m-r-footer">
          <div class="m-r-cart" @click="showQutoe">
            <div class="c-cart-icon">
              <div><i class="iconfont icon-gouwuche"></i></div>
              <div>报价</div>
              <div class="dot" v-if="quoteGoodsList.length">
                <p>{{ quoteGoodsList.length }}</p>
              </div>
            </div>
          </div>
          <div class="m-r-cart" @click="showInquiry">
            <div class="c-cart-icon">
              <div><i class="iconfont icon-gouwuche"></i></div>
              <div>询价</div>
              <div class="dot" v-if="cartGoods.length">
                <p>{{ cartGoods.length }}</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <!-- 询价弹框 -->
  <inquiry-Model
    v-model="inquiryShow"
    :data="cartGoods"
    @delete="deleteCartGoods"
    @success="cartSuccess"
  />
  <!-- 报价弹框 -->
  <quoteModel v-model="qutoeShow" :data="quoteGoodsList" @delete="deleteCartGoods" />
  <!-- 生成链接弹框 -->
  <share-link-dialog v-model="shareLinkShow" :inquiryId="inquiryId" />
  <!-- 搜索商品弹框 -->
  <searchGoods
    v-model="showSearchGoods"
    :val="searchInput"
    :skuId="skuId"
    isQuery
    :multiple="false"
    numKey="num"
    @add="searchGoodsConfirm"
  ></searchGoods>
</template>

<script>
import specsEenclosureScreen from "../components/specs-enclosure-screen.vue"; //附件规格筛选
import specsNoumenonScreen from "../components/specs-noumenon-screen.vue"; //本体规格筛选
import goodsMixin from "../mixins/goods.js";
import tableMixin from "../mixins/table.js";
import ContactWechat from "@/components/publicComponent/ContactWechat";
import ToAddInquiryPage from "@/components/publicComponent/toAddInquiryPage";
// import searchGoodsInput from "@/components/publicComponent/searchGoodsInput.vue";
import searchGoods from "../components/dialog/searchGoods.vue";
import api from "@/common/js/api.js";
import request from "@/common/js/request.js";

export default {
  data() {
    return {
      showSearchGoods: false,
      searchVal: "", //搜索字段
      visible: false,
      showSearchList: false,
      seriesList2: [],
      searchList: [],
      searchInput: "",
      timer: null,
      skuId: null,
      bannerList: [],
    };
  },
  mixins: [goodsMixin, tableMixin],
  components: {
    ContactWechat,
    specsEenclosureScreen,
    specsNoumenonScreen,
    ToAddInquiryPage,
    searchGoods,
  },
  created() {
    this.init();
    this.getbannerList();
  },
  methods: {
    openNewUrl() {
      if (this.ruleForm.officialWebsite) {
        window.open(this.ruleForm.officialWebsite, "_blank");
      } else {
        this.$message({
          message: "暂未设置官网跳转",
          type: "warning",
        });
      }
    },
    treeChange(node, type) {
      console.log(node);
      this.clearData(); //清空数据
      this.ruleForm[type] = node.id;
      this.ruleForm.officialWebsite = node.officialWebsite;
      if (type === "brandId") {
        //厂家切换
        // this.getseriesList2();
        this.getseriesList22("ruleForm", true); //获取当前系列
      } else if (type === "seriesId") {
        //系列切换
        this.getData();
      }
    },

    searchGoods(val) {
      this.skuId = null;
      //搜索商品的点击事件
      this.showSearchGoods = true;
      this.searchVal = val;
    },
    searchGoodsConfirm(data) {
      this.ruleForm = {
        brandId: data[0].brandId,
        seriesId: data[0].seriesId,
        type: "SUBJECT",
      };
      if (data[0].brandId) {
        this.productInfo = data[0];
        this.goodsList = data;
        this.getseriesList2(data[0].seriesId, data[0].skuSpecIdx); //获取当前品牌的系列id
      }
    },
    changeProduct(scope) {
      this.visible = false;
      this.getGoodsList(scope.row.skuReplaceVOList);
    },
    // 替换商品直接加购物车 报价单
    clickAddCart(scope) {
      this.addData("INQUIRY", scope.row.skuReplaceVOList);
    },
    clickAddQuoteList(scope) {
      this.addData("QUOTE", scope.row.skuReplaceVOList);
    },
    //系列改变
    seriesChange(val) {
      if (!val) {
        this.ruleForm.seriesId = "";
      } else {
        this.ruleForm.seriesId = val[Object.keys(val).length - 1];
        console.log(this.ruleForm.seriesId);
        this.getData();
      }
    },
    //搜索商品列表
    searchGoodsList() {
      if (this.timer) {
        clearTimeout(this.timer);
      }
      if (!this.searchInput) {
        this.searchList = [];
        return;
      }
      this.timer = setTimeout(() => {
        request.get(api.searchEs + "?name=" + this.searchInput).then(({ data }) => {
          this.searchList = data?.data?.data;
        });
      }, 200);
    },
    getbannerList() {
      request
        .post(api.bannerList, {
          pageNo: 1,
          pageSize: 999999,
        })
        .then(({ data }) => {
          this.bannerList = data.data.rows;
        });
    },
    clickSearchItem(row) {
      this.showSearchList = false;
      this.skuId = row.id;
      this.showSearchGoods = true;
    },
  },
  watch: {
    goodsList(a, b) {
      a = JSON.parse(JSON.stringify(a));
      b = JSON.parse(JSON.stringify(b));
      if (a.some((w) => !b.some((v) => v.id === w.id))) {
        var arr = [];
        a.map((item) => {
          if (item.active || !b.some((v) => v.id === item.id)) {
            item.active = true;
            arr.push(item);
          }
        });
        this.goodsList = a;
        this.$nextTick(() => {
          this.$refs.table.$refs.lidaTable.clearSelection();
          arr.map((item) => {
            this.$refs.table.$refs.lidaTable.toggleRowSelection(item, true);
          });
        });
      }
    },
  },
};
</script>

<style scoped lang="less">
@import "../style/index.less";
</style>
